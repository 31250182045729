/* eslint-disable no-unused-vars */
var mixin = {
  data: function() {
    return {
      dataForm: {},
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "编辑",
        create: "创建"
      }
    };
  },
  methods: {
    onCreate(data) {},
    onCreateData() {},
    resetForm() {},
    onUpdate(data) {},
    onUpdateData(data) {},
    onDelete(data) {}
  }
};

export default mixin;
