<template lang="pug">
    div.app-container
        //- 查询和其他操作
        div.filter-container
            el-button.filter-item(v-permission="['system:role:create']" type="primary" icon="el-icon-edit" @click="onCreate") 添加

        //- 查询结果
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="center" label="角色代码" prop="code")
            el-table-column(align="center" label="角色名称" prop="name")
            el-table-column(align="center" label="操作").small-padding.fixed-width
                template(slot-scope="scope")
                    el-button(v-permission="['system:role:update']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-button(v-permission="['system:role:delete']" type="danger" size="mini" @click="onDelete(scope.row)") 删除
                    el-button(v-permission="['system:role:update-permission']" type="warning" size="mini" @click="onUpdatePermission(scope.row)") 授权

        //- 分页控件

        //- 添加或修改对话框
        el-dialog(:title="textMap[dialogStatus]" :visible.sync="dialogFormVisible")
            el-form(ref="dataForm" :rules="rules" :model="dataForm" status-icon label-position="left" label-width="100px" style="width: 400px; margin-left:50px;")
                el-form-item(label="角色代码" prop="code")
                    el-input(v-model="dataForm.code" :disabled="dialogStatus === 'update'")
                el-form-item(label="角色名称" prop="name")
                    el-input(v-model="dataForm.name")
            div(slot="footer").dialog-footer
                el-button(@click="dialogFormVisible = false") 取消
                el-button(v-if="dialogStatus=='create'" type="primary" @click="onCreateData") 确定
                el-button(v-else type="primary" @click="onUpdateData") 确定
        
        //- 权限配置对话框
        el-dialog(:visible.sync="permissionDialogFormVisible" title="权限配置")
            el-tree(ref="tree" :data="systemPermissions" :default-checked-keys="assignedPermissions" show-checkbox node-key="id" highlight-current)
                span(slot-scope="{ node, data }").custom-tree-node
                    span {{ data.label }}
                    el-tag(v-if="data.api" type="success" size="mini") {{ data.id }}
                    el-tag(v-if="data.api" type="warning" size="mini") {{ data.api }}
            div(slot="footer").dialog-footer
                el-button(@click="permissionDialogFormVisible = false") 取消
                el-button(:loading="permissionLoading" type="primary" @click="onUpdatePermissionData") 确定

</template>

<script>
import {
  getRoleList,
  createRole,
  updateRole,
  deleteRole,
  getPermission,
  updatePermission
} from "@/api/system";
import _ from "lodash";
import DialogFormMixin from "@/mixins/dialog-form-parts";

export default {
  name: "Role",
  mixins: [DialogFormMixin],
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      permissionLoading: false,
      rules: {
        // eslint-disable-next-line prettier/prettier
        code: [{ required: true, message: "角色代码不能为空", trigger: "blur" }],
        name: [{ required: true, message: "角色名称不能为空", trigger: "blur" }]
      },
      permissionDialogFormVisible: false,
      systemPermissions: null,
      assignedPermissions: null,
      permissionForm: {
        code: undefined,
        permissions: []
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      this.listLoading = true;
      const resp = await getRoleList(this.listQuery);
      this.list = resp.data.data.items;
      this.listLoading = false;
    },
    resetForm() {
      this.dataForm = {
        code: undefined,
        name: undefined
      };
    },
    onCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    onCreateData() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const resp = await createRole(this.dataForm);
          this.list.unshift(resp.data.data);
          this.dialogFormVisible = false;
          this.$notify.success({
            title: "成功",
            message: "添加角色成功"
          });
        }
      });
    },
    onUpdate(row) {
      this.dataForm = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    onUpdateData() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid) {
          const resp = await updateRole(this.dataForm);
          const _item = resp.data.data;
          const item = _.find(this.list, { code: _item.code });
          _.assign(item, _item);
          this.dialogFormVisible = false;
          this.$notify.success({
            title: "成功",
            message: "更新角色成功"
          });
        }
      });
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将永久删除该角色---" + row.name + "---, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        await deleteRole(row);

        // 提示操作成功
        this.$notify.success({
          title: "成功",
          message: "删除角色成功"
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      } catch (error) {
        return false;
      }
    },
    async onUpdatePermission(row) {
      this.permissionDialogFormVisible = true;
      this.permissionForm.code = row.code;
      const resp = await getPermission(row);
      this.systemPermissions = resp.data.data.systemPermissions;
      this.assignedPermissions = resp.data.data.assignedPermissions;
    },
    async onUpdatePermissionData() {
      this.permissionLoading = true;
      this.permissionDialogFormVisible = false; // 因为dialog在打开状态不会更新dom中的内容
      this.permissionDialogFormVisible = true;
      this.permissionForm.perms = this.$refs.tree.getCheckedKeys(true);
      await updatePermission(this.permissionForm);

      this.permissionDialogFormVisible = false;
      this.$notify.success({
        title: "成功",
        message: "更新角色权限成功"
      });
      this.permissionLoading = false;
    }
  }
};
</script>
